import { createSlice } from "@reduxjs/toolkit";
import {
  treatments_fetchAllData,
  treatments_fetchDataBySlug,
} from "./treatmentsActions";
const initialState = {
  all_treatments_data: null,
  all_treatments_loading: false,
  all_treatments_error: false,
  all_treatments_errCode: null,

  treatment_data: null,
  treatment_loading: false,
  treatment_error: false,
  treatment_errCode: null,
};

const treatmentsSlice = createSlice({
  name: "treatments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(treatments_fetchAllData.pending, (state) => {
        state.all_treatments_loading = true;
        state.all_treatments_error = false;
      })
      .addCase(treatments_fetchAllData.fulfilled, (state, action) => {
        state.all_treatments_loading = false;
        state.all_treatments_error = false;
        state.all_treatments_data = action.payload;
      })
      .addCase(treatments_fetchAllData.rejected, (state, action) => {
        state.all_treatments_loading = false;
        state.all_treatments_error = true;
        state.all_treatments_errCode = action.error.message;
      })

      .addCase(treatments_fetchDataBySlug.pending, (state) => {
        state.treatment_loading = true;
        state.treatment_error = false;
      })
      .addCase(treatments_fetchDataBySlug.fulfilled, (state, action) => {
        state.treatment_loading = false;
        state.treatment_error = false;
        state.treatment_data = action.payload;
      })
      .addCase(treatments_fetchDataBySlug.rejected, (state, action) => {
        state.treatment_loading = false;
        state.treatment_error = true;
        state.treatment_errCode = action.error.message;
      });
  },
});

export default treatmentsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { about_me_fetchAllData, about_me_updateData } from "./aboutMeActions";

const initialState = {
  about_me_data: null,
  about_me_loading: false,
  about_me_error: false,
  about_me_errCode: null,
};

const aboutMeSlice = createSlice({
  name: "aboutMe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(about_me_fetchAllData.pending, (state) => {
        state.about_me_loading = true;
        state.about_me_error = null;
      })
      .addCase(about_me_fetchAllData.fulfilled, (state, action) => {
        state.about_me_loading = false;
        state.about_me_error = false;
        state.about_me_data = action.payload;
      })
      .addCase(about_me_fetchAllData.rejected, (state, action) => {
        state.about_me_loading = false;
        state.about_me_error = action.error.message;
      });
  },
});

export default aboutMeSlice.reducer;

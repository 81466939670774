import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../../services/publicAPIServices";

const API = "contact/";

// Action to fetch all data
export const contact_fetchAllData = createAsyncThunk(
  "contacts/get-all-data",
  async () => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const contact_updateData = createAsyncThunk(
  "contacts/update-data",
  async (payload) => {
    // const {data } = payload;
    try {
      const response = await publicAPIServices.updateData(
        API + "update-data",
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

// formSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { form_postData, review_postData } from "./formActions";

const initialState = {
  form_loading: false,
  form_success: false,
  form_error: false,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetSendFormState: (state) => {
      state.form_loading = false;
      state.form_success = false;
      state.form_error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(form_postData.pending, (state) => {
        state.form_loading = true;
        state.isError = false;
      })
      .addCase(form_postData.fulfilled, (state, action) => {
        state.form_loading = false;
        state.form_error = false;
        state.form_success = true;
      })
      .addCase(form_postData.rejected, (state, action) => {
        state.form_loading = false;
        state.form_error = true;
      })
      .addCase(review_postData.pending, (state) => {
        state.form_loading = true;
        state.isError = false;
      })
      .addCase(review_postData.fulfilled, (state, action) => {
        state.form_loading = false;
        state.form_error = false;
        state.form_success = true;
      })
      .addCase(review_postData.rejected, (state, action) => {
        state.form_loading = false;
        state.form_error = true;
      });
  },
});

export const { resetSendFormState } = formSlice.actions;

export default formSlice.reducer;

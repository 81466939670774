import { createAsyncThunk } from "@reduxjs/toolkit";
// import { homepage_backend } from "../../../dummyData/dummy_functions";
import publicAPIServices from "../../../services/publicAPIServices";
const API = "treatments/";

// // Action to fetch all data
export const treatments_fetchAllData = createAsyncThunk(
  "treatments/fetchAll",
  async () => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const treatments_fetchDataBySlug = createAsyncThunk(
  "treatments/fetchBySlug",
  async (slug) => {
    try {
      const response = await publicAPIServices.fetchData(
        API + "get-data/" + slug
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

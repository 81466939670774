import { createSlice } from "@reduxjs/toolkit";
import { homepage_fetchAllData } from "./homepageActions";
const initialState = {
  homepage_data: null,
  homepage_loading: false,
  homepage_error: false,
  homepage_errCode: null,
};

const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(homepage_fetchAllData.pending, (state) => {
        state.homepage_loading = true;
        state.homepage_error = false;
      })
      .addCase(homepage_fetchAllData.fulfilled, (state, action) => {
        state.homepage_loading = false;
        state.homepage_error = false;
        state.homepage_data = action.payload;
      })
      .addCase(homepage_fetchAllData.rejected, (state, action) => {
        state.homepage_loading = false;
        state.homepage_error = true;
        state.homepage_errCode = action.error.message;
      });
  },
});

export default homepageSlice.reducer;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import langReducer from "./slices/langSlice/langSlice";
import homepageReducer from "./pages/homepageSlices/homepageReducers";
import formReducer from "./slices/formSlice/formReducers";
import pricesReducer from "./slices/priceSlice/pricesReducer";
import treatmentsReducer from "./slices/treatmentsSlice/treatmentsReducer";
import aboutMeReducers from "./pages/aboutMeSlices/aboutMeReducers";
import contactsReducers from "./slices/contactsSlices/contactsReducers";
import workoutReducers from "./slices/workoutSlices/workoutReducers";
import exercisesReducer from "./slices/exercisesSlices/exercisesReducer";
import authReducer from "./auth/authSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  lang: langReducer,
  homepage: homepageReducer,
  form: formReducer,
  treatments: treatmentsReducer,
  prices: pricesReducer,
  auth: authReducer,
  aboutMe: aboutMeReducers,
  contacts: contactsReducers,
  workout: workoutReducers,
  exercises: exercisesReducer,
});

// const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // devTools: false,
});

import { createSlice } from "@reduxjs/toolkit";
import {
  workouts_fetchAllData,
  workouts_fetchDataByID,
} from "./workoutActions";
const initialState = {
  all_workouts_data: null,
  all_workouts_loading: false,
  all_workouts_error: false,
  all_workouts_errCode: null,

  workout_data: {
    phases: [],
    name: null,
    _id: null,
  }, // Change from `workout_data` to `workout_data`
  workout_loading: false,
  workout_error: false,
  workout_errCode: null,
};
const workoutSlice = createSlice({
  name: "workout",
  initialState,
  // initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(workouts_fetchAllData.pending, (state) => {
        state.all_workouts_loading = true;
        state.all_workouts_error = null;
      })
      .addCase(workouts_fetchAllData.fulfilled, (state, action) => {
        state.all_workouts_loading = false;
        state.all_workouts_error = false;
        state.all_workouts_data = action.payload;
      })
      .addCase(workouts_fetchAllData.rejected, (state, action) => {
        state.all_workouts_loading = false;
        state.all_workouts_error = action.error.message;
      })
      .addCase(workouts_fetchDataByID.pending, (state) => {
        state.workout_loading = true;
        state.workout_error = null;
      })
      .addCase(workouts_fetchDataByID.fulfilled, (state, action) => {
        state.workout_loading = false;
        state.workout_error = false;
        state.workout_data.phases = action.payload.phases;
        state.workout_data.name = action.payload.name;
        state.workout_data._id = action.payload._id;
      })
      .addCase(workouts_fetchDataByID.rejected, (state, action) => {
        state.workout_loading = false;
        state.workout_error = action.error.message;
      });
  },
});

export const {
  setWorkout,
  addPhase,
  addOption,
  addRound,
  deletePhase,
  deleteOption,
  deleteRound,
  updatePhaseName,
  updateOptionName,
  updateRoundName,
  addExercise,
  deleteExercise,
  moveExercise,
  editExerciseRepeats,
  editExerciseUnit,
  updatePlanName,
} = workoutSlice.actions;

export default workoutSlice.reducer;

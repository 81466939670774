import { createSlice } from "@reduxjs/toolkit";
import { contact_fetchAllData, contact_updateData } from "./contactsActions";

const initialState = {
  contact_data: null,
  contact_loading: false,
  contact_error: false,
  contact_errCode: null,
};

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(contact_fetchAllData.pending, (state) => {
        state.contact_loading = true;
        state.contact_error = null;
      })
      .addCase(contact_fetchAllData.fulfilled, (state, action) => {
        state.contact_loading = false;
        state.contact_error = false;
        state.contact_data = action.payload;
      })
      .addCase(contact_fetchAllData.rejected, (state, action) => {
        state.contact_loading = false;
        state.contact_error = action.error.message;
      })

      .addCase(contact_updateData.pending, (state) => {
        state.contact_loading = true;
        state.contact_error = null;
      })
      .addCase(contact_updateData.fulfilled, (state, action) => {
        state.contact_loading = false;
        state.contact_error = false;
        state.contact_data = action.payload;
      })
      .addCase(contact_updateData.rejected, (state, action) => {
        state.contact_loading = false;
        state.contact_error = action.error.message;
      });
  },
});

export default contactSlice.reducer;

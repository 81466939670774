import { createAsyncThunk } from "@reduxjs/toolkit";
import { homepage_backend } from "../../../dummyData/dummy_functions";
const API = "homepage/";

export const homepage_fetchAllData = createAsyncThunk(
  "homepage/fetchAll",
  async () => {
    try {
      const response = homepage_backend();
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

// // Action to fetch all data
// export const fetchAllData = createAsyncThunk("faq/fetchAll", async () => {
//   try {
//     const response = await publicAPIServices.fetchData(API + "get-all-data");
//     return response;
//   } catch (error) {
//     throw new Error(error.status);
//   }
// });

// // Action to fetch treatment by slug
// export const fetchDataBySlug = createAsyncThunk("faq/fetchTreatmentBySlug", async () => {
//   try {
//     const response = await publicAPIServices.fetchData(API + "get-data-by-slug");
//     return response;
//   } catch (error) {
//     throw new Error(error.status);
//   }
// });

import axios from "axios";
import config from "../../config/config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import jwt_decode from "jwt-decode";
import publicAPIServices from "../../services/publicAPIServices";
import privateAPIServices from "../../services/privateAPIServices";

const PUBLIC_API_URL = config.API_URL + "/api/v1/public/auth/";
const PRIVATE_API_URL = config.API_URL + "/api/v1/private/auth/";
const API = "user/";

// Register user
// const register = async (userData) => {
//   const response = await axios.post(PRIVATE_API_URL+"register", userData);

//   if (response.data) {
//     localStorage.setItem("user", JSON.stringify(response.data));
//   }

//   return response.data;
// };

// Login user
const login = async (userData) => {
  const response = await axios.post(PUBLIC_API_URL + "login", userData, {
    withCredentials: true,
  });
  const decoded = jwt_decode(response.data.session_data);
  const expire = 10000;
  Cookies.set("authenticated", 1, { expires: expire });
  Cookies.set("session_time", expire, { expires: expire });
  Cookies.set("session_data", response.data.session_data, {
    expires: expire,
  });
  return response.data;
};

//refresh token
const refreshToken = async () => {
  try {
    const response = await axios.post(
      PRIVATE_API_URL + "refresh-token",
      {},
      {
        withCredentials: true,
      }
    );

    const decoded = jwt_decode(response.data.session_data);
    const expire = new Date(Date.now() + decoded.expires);
    Cookies.set("authenticated", 1, { expires: expire });
    Cookies.set("session_time", expire, { expires: expire });
    Cookies.set("session_data", response.data.session_data, {
      expires: expire,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Logout user
const logout = async () => {
  try {
    await axios.post(PRIVATE_API_URL + "logout", {}, { withCredentials: true });

    //   Cookies.remove("access_token");
    //   return response.data;
  } catch (error) {
    console.log(error);
  }
};

//activate user
export const activateUser_postData = createAsyncThunk(
  "auth/activate-user",
  async (payload) => {
    const { token, password } = payload;
    const data = {
      password,
    };
    try {
      const response = await publicAPIServices.postData(
        API + "activate-account/" + token,
        data
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const resetPassword_postData = createAsyncThunk(
  "auth/reset-password",
  async (payload) => {
    const { token, password } = payload;
    const data = {
      password,
    };
    try {
      const response = await publicAPIServices.postData(
        API + "reset-password/" + token,
        data
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const forgottenPassword_postData = createAsyncThunk(
  "auth/forgotten-password",
  async (payload) => {
    const { email } = payload;
    const data = {
      email,
    };
    try {
      const response = await publicAPIServices.postData(
        API + "forgotten-password",
        data
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const userProfile_updateData = createAsyncThunk(
  "auth/update-profile-data",
  async (payload) => {
    try {
      const response = await privateAPIServices.updateData(
        "/" + API + "update-data/",
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

const authServices = {
  // register,
  logout,
  login,
  refreshToken,
  activateUser_postData,
};

export default authServices;

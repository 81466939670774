import { createSlice } from "@reduxjs/toolkit";

//const lang = localStorage.getItem("lng");

const initialState = {
  lang: "hu",
  loading: false,
  error: "",
};

const langSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    // selectHungarian: (state) => {
    //   state.lang = "hu";
    //   localStorage.setItem("hu");
    // },
  },
});

export const { selectHungarian } = langSlice.actions;

export default langSlice.reducer;

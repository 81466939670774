import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";

const Header = lazy(() => import("./layouts/Header/Header"));
const Footer = lazy(() => import("./layouts/Footer/Footer"));
const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
const Login = lazy(() => import("./pages/Login/Login"));
const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Prices = lazy(() => import("./pages/Prices/Prices"));

const AllTreatments = lazy(() =>
  import("./components/Treatments/AllTreatments")
);
const SelectedTreatment = lazy(() =>
  import("./pages/Treatments/SelectedTreatment/SelectedTreatment")
);

//user pages
const UserActivationPage = lazy(() =>
  import("./pages/UserActivationPage/UserActivationPage")
);
const UserSettings = lazy(() =>
  import("./pages/UserPages/UserSettings/UserSettings")
);

const UserExercises = lazy(() =>
  import("./pages/UserPages/UserExercises/UserExercises")
);
const Dashboard = lazy(() => import("./pages/UserPages/Dashboard/Dashboard"));
const UserWorkouts = lazy(() =>
  import("./pages/UserPages/UserWorkouts/UserWorkouts")
);
const UserReview = lazy(() =>
  import("./pages/UserPages/UserReview/UserReview")
);

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <div className="app__container ">
        <Routes>
          <Route path="/profilom/velemeny" element={<UserReview />} />
          <Route path="/profilom/gyakorlatok" element={<UserExercises />} />
          <Route path="/profilom/beallitasok" element={<UserSettings />} />
          <Route path="/profilom/edzesterveim" element={<UserWorkouts />} />
          <Route path="/profilom" element={<Dashboard />} />
          <Route path="/activate/:id" element={<UserActivationPage />} />
          <Route path="/reset-password/:id" element={<UserActivationPage />} />

          <Route path="/kezelesek/:slug" element={<SelectedTreatment />} />
          <Route path="/kezelesek" element={<AllTreatments />} />
          <Route path={"/bejelentkezes"} element={<Login />} />
          <Route path="/rolam" element={<About />} />
          <Route path="/elerhetoseg" element={<Contact />} />
          <Route path="/arlista" element={<Prices />} />
          <Route path={"/"} exact element={<Homepage />} />
        </Routes>
      </div>
      <Footer />
    </Suspense>
  );
};

export default App;

import { createSlice } from "@reduxjs/toolkit";
import {
  exercises_fetchAllData,
  exercises_fetchByID,
} from "./exercisesActions";

const initialState = {
  exercises_data: null,
  exercises_loading: false,
  exercises_error: false,
  exercises_errCode: null,

  exercise_data: null,
  exercise_loading: false,
  exercise_error: false,
  exercise_errCode: null,
};

const exercisesSlice = createSlice({
  name: "exercises",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exercises_fetchAllData.pending, (state) => {
        state.exercises_loading = true;
        state.exercises_error = false;
      })
      .addCase(exercises_fetchAllData.fulfilled, (state, action) => {
        state.exercises_loading = false;
        state.exercises_error = false;
        state.exercises_data = action.payload;
      })
      .addCase(exercises_fetchAllData.rejected, (state, action) => {
        state.exercises_loading = false;
        state.exercises_error = true;
        state.exercises_errCode = action.error.message;
      })
      .addCase(exercises_fetchByID.pending, (state) => {
        state.exercise_loading = true;
        state.exercise_error = false;
      })
      .addCase(exercises_fetchByID.fulfilled, (state, action) => {
        state.exercise_loading = false;
        state.exercise_error = false;
        state.exercise_data = action.payload;
      })
      .addCase(exercises_fetchByID.rejected, (state, action) => {
        state.exercise_loading = false;
        state.exercise_error = true;
        state.exercise_errCode = action.error.message;
      });
  },
});

export default exercisesSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import publicAPIServices from "../../../services/publicAPIServices";
import privateAPIServices from "../../../services/privateAPIServices";
const API = "informations/";

export const form_postData = createAsyncThunk(
  "form/postData",
  async (payload) => {
    try {
      const response = await publicAPIServices.postData(
        API + "send-message",
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const review_postData = createAsyncThunk(
  "form/send-review",
  async (payload) => {
    try {
      const response = await privateAPIServices.postData(
        "/" + API + "send-review",
        payload
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

import axios from "axios";
import config from "../config/config";

const API = config.API_URL + config.API_VERSION + "/public/";

const fetchData = async (url) => {
  try {
    const response = await axios.get(API + url);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
};

const postData = async (url, data) => {
  try {
    const response = await axios.post(API + url, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
};
// Action to fetch all data
// const API = "services/";

// export const fetchAllData = createAsyncThunk("service/fetchAll", async () => {
//   try {
//     const response = await publicAPIServices.fetchData(API + "get-all-data");
//     if (!Array.isArray(response)) {
//       return null;
//     } else {
//       return response;
//     }
//   } catch (error) {
//     throw new Error(error.status);
//   }
// });

// https://localhost:5000/api/v1/public/services/get-all-data

//PRIVATE
// const fetchData = async (url, accessToken) => {
//   try {
//     const response = await axios.get(
//       API_URL + url,
//BODY        {},
//       {
// HEADER        withCredentials: true,
//       }
//     );
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

const publicAPIServices = {
  fetchData,
  postData,
};

export default publicAPIServices;

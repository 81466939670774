import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./assets/styles/app.style.css";

import { BrowserRouter } from "react-router-dom";
import { store } from "./context/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
        <App />
        {/* </PersistGate> */}
      </Provider>
    </HelmetProvider>
  </BrowserRouter>
);

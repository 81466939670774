import { createAsyncThunk } from "@reduxjs/toolkit";
import privateAPIServices from "../../../services/privateAPIServices";

const API = "/workout/";

// Action to fetch all data
export const workouts_fetchAllData = createAsyncThunk(
  "workouts/fetchAll",
  async () => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "user/get-all-data/"
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

export const workouts_fetchDataByID = createAsyncThunk(
  "workouts/fetchByID",
  async (ID) => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "user/get-data-by-id/" + ID
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
// import { homepage_backend } from "../../../dummyData/dummy_functions";
import publicAPIServices from "../../../services/publicAPIServices";
const API = "prices/";

// // Action to fetch all data
export const prices_fetchAllData = createAsyncThunk(
  "prices/fetchAll",
  async () => {
    try {
      const response = await publicAPIServices.fetchData(API + "get-all-data");
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
// import { homepage_backend } from "../../../dummyData/dummy_functions";
import publicAPIServices from "../../../services/publicAPIServices";
import privateAPIServices from "../../../services/privateAPIServices";
const API = "/exercise/";

// // Action to fetch all data
export const exercises_fetchAllData = createAsyncThunk(
  "exercises/fetchAll",
  async () => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "get-all-data-by-user"
      );
      return response;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

// // Action to fetch all data
export const exercises_fetchByID = createAsyncThunk(
  "exercises/fetchById",
  async (id) => {
    try {
      const response = await privateAPIServices.fetchData(
        API + "get-all-data-by-user"
      );
      // Filter the response data based on _id === id
      const filteredData = response.filter(
        (item) => item._id === "6548fa7091779e3095afcf37"
      );
      console.log(filteredData);
      return filteredData;
    } catch (error) {
      throw new Error(error.status);
    }
  }
);

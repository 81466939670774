import homepage_data from "./dummy_homepage_data";
import form_data from "./dummy_form_data";

export const homepage_backend = () => {
  const error = null; //string
  return new Promise((resolve, reject) => {
    // Simulate an API call delay (you can adjust this delay as needed)
    setTimeout(() => {
      if (error === null) {
        resolve(homepage_data); // Simulate a successful response with the mock data
      } else {
        reject("500"); // Simulate a 404 http code
        // resolve(error); // Simulate a 500 http code
      }
    }, 200);
  });
};

export const form_backend = () => {
  //tesztváltozó
  const testError = null; //string
  //3 állapot: pending, resolve, reject
  return new Promise((resolve, reject) => {
    // Simulate an API call delay (you can adjust this delay as needed)
    //loading szimulálása
    setTimeout(() => {
      if (testError === null) {
        //200-as kód esetén ez van
        resolve(form_data); // Simulate a successful response with the mock data
      } else {
        reject("500"); // Simulate a 404 http code
        // resolve(error); // Simulate a 500 http code
      }
    }, 200);
  });
};

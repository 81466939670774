import { createSlice } from "@reduxjs/toolkit";
import { prices_fetchAllData } from "./pricesActions";

const initialState = {
  prices_data: null,
  prices_loading: false,
  prices_error: false,
  prices_errCode: null,
};

const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(prices_fetchAllData.pending, (state) => {
        state.prices_loading = true;
        state.prices_error = false;
      })
      .addCase(prices_fetchAllData.fulfilled, (state, action) => {
        state.prices_loading = false;
        state.prices_error = false;
        state.prices_data = action.payload;
      })
      .addCase(prices_fetchAllData.rejected, (state, action) => {
        state.prices_loading = false;
        state.prices_error = true;
        state.prices_errCode = action.error.message;
      });
  },
});

export default pricesSlice.reducer;
